import React, { Component } from 'react';
import styled from 'styled-components';
import { listDbs, initDb, getCurrent, deleteDb } from '../../services/db';
import ImportExport from '../ImportExport/ImportExport';
import Flexbox from '../Layout/Flexbox';
import CloseButton from '../Buttons/CloseButton';

type State = { dbs: null | string[] };

const DbItem = styled.div`
  display: flex;
  justify-content: space-between;
  label {
    margin-left: 1em;
  }
`;

const Form = styled.form`
  margin-top: 1em;
`;

class Options extends Component<{}, State> {
  input: HTMLInputElement | null = null;

  state: State = {
    dbs: null
  };

  componentDidMount() {
    this.updateDbList();
  }

  updateDbList = async () => {
    const dbs = await listDbs();
    this.setState({
      dbs
    });
  };
  onSubmit = async (e: any) => {
    e.preventDefault();
    if (!this.input?.value) return;
    const db = initDb(this.input.value);

    this.input.value = '';
    await db.open();
    this.updateDbList();
  };
  deleteDb = async (dbName: string) => {
    if (
      window.confirm(
        `Do you want to irreversibly delete the database ${dbName}?`
      )
    ) {
      try {
        await deleteDb(dbName);
        const dbs = await listDbs();
        const db = initDb(dbs[0]);
        await db.open();
        setTimeout(this.updateDbList, 100);
      } catch (e) {
        console.error(e);
      }
    }
  };

  render() {
    const { dbs } = this.state;
    if (dbs === null) return null;
    const current = getCurrent();
    return (
      <Flexbox flexDirection="column" gap="0.5rem" data-cy="db-list">
        {dbs.map(db => (
          <DbItem key={db}>
            <span>
              <input
                id={db}
                type="radio"
                value={db}
                checked={db === current}
                onChange={e => {
                  const db = initDb(e.target.value);

                  db.open().then(this.updateDbList);
                }}
              />
              <label htmlFor={db}>{db}</label>
            </span>

            <CloseButton onClick={() => this.deleteDb(db)} />
          </DbItem>
        ))}
        <Form onSubmit={this.onSubmit}>
          Create new database
          <Flexbox gap="0.5rem">
            <input
              className="input"
              ref={el => (this.input = el)}
              data-cy="db-add-input"
              type="text"
              name="newdb"
              placeholder="New db name"
            />
            {/* <Button data-cy="db-add-submit" type="primary" htmlType="submit">
              Create
            </Button> */}
            <button className="button" type="submit">
              Create
            </button>
          </Flexbox>
        </Form>

        <hr style={{ margin: '1rem 0' }} />

        <ImportExport />
      </Flexbox>
    );
  }
}

export default Options;
