import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Flexbox from '../Layout/Flexbox';

const MenuItem = styled.span<{ active?: boolean }>`
  color: ${p => (p.active ? 'var(--primary-color)' : '#555')};
  a {
    white-space: nowrap;
    color: inherit;
    display: block;
    line-height: 2rem;
    padding: 0 1rem;
    border-bottom: ${p =>
      `2px solid ${p.active ? 'var(--primary-color)' : 'lightgrey'}`};
  }
  &:hover {
    background-color: #ebebeb;
  }
  a:focus {
    outline: 2px solid var(--highlight-color);
  }
`;

const MainMenu = () => {
  const location = useLocation();

  return (
    <Flexbox justifyContent="center">
      <MenuItem
        active={location.pathname === '/' || location.pathname === '/edit'}
      >
        <Link to="/">create</Link>
      </MenuItem>
      <MenuItem active={location.pathname === '/list'}>
        <Link to="/list">list</Link>
      </MenuItem>
      <MenuItem active={location.pathname === '/configuration'}>
        <Link to="/configuration">custom options</Link>
      </MenuItem>
      <MenuItem active={location.pathname === '/options'}>
        <Link to="/options">settings</Link>
      </MenuItem>
    </Flexbox>
  );
};

export default MainMenu;
