import { useEffect, useState } from 'react';
import { list } from '../services/db';
import { uniq } from 'ramda';

const useAutocomplete = () => {
  const [autocomplete, setAutocomplete] = useState<string[]>([]);

  useEffect(() => {
    if (autocomplete.length === 0) {
      list(1, 1000).then(res => {
        // unique and only 20 of them
        const ac = uniq(res.map(r => r.event)).slice(0, 20);
        setAutocomplete(ac);
      });
    }
  }, [autocomplete.length, setAutocomplete]);

  return autocomplete;
};

export default useAutocomplete;
