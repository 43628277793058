import { format, differenceInHours, formatDistance } from 'date-fns';
import moment from 'moment';

function formatDate(date: Parameters<typeof moment>[0]) {
  return moment(date).format('YYYY-MM-DD HH:mm');
}

function formatForInput(date: Parameters<typeof format>[0]) {
  return format(date, 'YYYY-MM-DDTHH:mm:ss');
}

function showDate(date: string | number | Date) {
  const now = new Date();

  if (differenceInHours(now, new Date(date)) < 1) {
    const dist = formatDistance(now, new Date(date), {
      addSuffix: true
    });
    return dist;
  }

  return formatDate(date);
}

const recordedDate = () => moment().format();

export { formatDate, formatForInput, showDate, recordedDate };
