import React, { Component, Fragment } from 'react';
import { download } from '../../services/download';
import { exportData } from '../../services/db';
import Flexbox from '../Layout/Flexbox';

export default class Export extends Component {
  textarea: null | any = null;

  state = {
    content: ''
  };

  componentDidMount() {
    this.onExport();
  }

  onExport = async () => {
    const content = await exportData();
    this.setState({
      content
    });
  };

  exportFromTextarea() {
    download(`export-${new Date().toISOString()}.json`, this.state.content);
  }

  copyToClipboard = () => {
    navigator.clipboard.writeText(this.state.content).then(
      function () {
        console.log('success');
      },
      function () {
        console.log('/* clipboard write failed */');
      }
    );
  };

  render() {
    const { content } = this.state;
    return (
      <Fragment>
        <div style={{ padding: '0.5rem' }}>
          Either copy the contents from below and import in another device or
          export to a file
          <textarea
            className="textarea"
            ref={el => (this.textarea = el)}
            onChange={({ target: { value } }) =>
              this.setState({
                content: value
              })
            }
            readOnly
            rows={10}
            value={content}
          />
          {content.length > 0 && (
            <Flexbox gap="0.5rem" style={{ marginTop: '0.5rem' }}>
              <button className="button" onClick={this.exportFromTextarea}>
                download file
              </button>
              <button className="button" onClick={this.copyToClipboard}>
                copy to clipboard
              </button>
            </Flexbox>
          )}
        </div>
      </Fragment>
    );
  }
}
