import Dexie from 'dexie';
import observable from 'dexie-observable';
import syncable from 'dexie-syncable';

export class DexieDB extends Dexie {
  logs: Dexie.Table<ILog, string>;
  customValues: Dexie.Table<ICustomValue, string>;

  constructor(dbName: string) {
    super(dbName, { addons: [observable, syncable] });

    //
    // Define tables and indexes
    // (Here's where the implicit table props are dynamically created)
    //

    this.version(1).stores({
      logs: '$$uuid,date,event,amount,unit,customValue'
    });
    this.version(2).stores({
      customValues: '$$uuid,unit,event,options'
    });

    this.logs = this.table('logs');
    this.customValues = this.table('customValues');
  }
}

// By defining the interface of table records,
// you get better type safety and code completion
export interface ILog {
  uuid: string;
  date: string;
  event: string;
  amount?: number;
  unit?: string;
  customValue?: string | number;
}

export interface ICustomValue {
  uuid: string;
  // updatedAt: string;
  event: string | number;
  unit?: string;
  options?: string[];
}

export interface ICustomValueTemp {
  event: string | number;
  unit?: string;
  options?: string[];
}

export type ILogTemp = Omit<ILog, 'uuid' | 'date'> & {
  uuid?: string;
  date?: string;
};
