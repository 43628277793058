import React, { Fragment, useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { list, remove, count, filteredList } from '../../services/db';
import List from './List';
import useAutocomplete from '../../hooks/useAutocomplete';
import Flexbox from '../Layout/Flexbox';
import CloseButton from '../Buttons/CloseButton';
import Pagination from '../Pagination/index';

const limit = 15;

const PaginatedList = () => {
  const [page, setPage] = useState(1);
  const [term, setTerm] = useState<string | null>(null);

  const items = useLiveQuery(
    () => (term ? filteredList(term, page) : list(page)),
    [page, term]
  );
  const itemCount = useLiveQuery(() => count());
  const autocomplete = useAutocomplete();

  return (
    <>
      <Fragment>
        <div style={{ overflowX: 'auto' }}>
          <Flexbox>
            {autocomplete
              .filter(acTerm => acTerm !== term)
              .map(acTerm => (
                <button
                  className="button"
                  style={{
                    margin: 1,
                    whiteSpace: 'nowrap',
                    padding: '1px 0.5rem'
                  }}
                  key={acTerm}
                  onClick={() => setTerm(acTerm)}
                >
                  {acTerm}
                </button>
              ))}
          </Flexbox>
        </div>
        {term && (
          <div style={{ margin: '0.5rem 0' }}>
            Showing <strong>{term}</strong>{' '}
            <CloseButton onClick={() => setTerm(null)} />
          </div>
        )}

        <List items={items ?? []} remove={remove} />

        <>
          <Pagination
            total={(itemCount ?? 0) / limit}
            current={page}
            next={() => setPage(page + 1)}
            prev={() => setPage(page - 1)}
          />
          {!term && <div>{itemCount} records</div>}
        </>
      </Fragment>
    </>
  );
};

export default PaginatedList;
