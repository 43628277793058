import React from 'react';
import styled from 'styled-components';
import { formatISO } from 'date-fns';
import Flexbox from '../Layout/Flexbox';

const Overlay = styled.div<{ disabled: boolean }>`
  color: ${props => (props.disabled ? '#ccc' : 'black')};

  button {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-shadow: none;
    box-shadow: none;
    background: transparent;
    height: 32px;
  }
`;

const Input = styled.input<{ happeningNow: boolean }>`
  border: 1px solid #d9d9d9;
  opacity: ${p => (p.happeningNow ? 0.3 : 1)};
  height: 2rem;
  max-width: 190px;
  border-radius: 4px;
`;

type Props = {
  date: string;
  changeDate: (d: string) => void;

  checkNow: (now: boolean) => void;
  now: boolean;
};

const TimeDate: React.FunctionComponent<Props> = ({
  date,
  changeDate,
  now,
  checkNow
}) => (
  <label className="label">
    time and date
    <div className="spaceEvenly">
      <Overlay disabled={now}>
        <Input
          type="datetime-local"
          value={formatISO(new Date(date)).slice(0, 16)}
          disabled={now}
          happeningNow={now}
          onChange={e => {
            changeDate(formatISO(new Date(e.target.value)));
          }}
        />
      </Overlay>
      <Flexbox
        gap="1rem"
        alignItems="center"
        style={{
          lineHeight: '2rem',
          marginLeft: '1rem'
        }}
      >
        <input
          id="happening-now"
          type="checkbox"
          checked={now}
          // @ts-ignore
          onChange={e => checkNow(e.target.checked)}
        />
        <label htmlFor="happening-now">happening now</label>
      </Flexbox>
    </div>
  </label>
);

export default TimeDate;
