import React from 'react';

type Props = {
  onClick: () => void;
};

const CloseButton = (props: Props) => {
  return (
    <button className="clear-button" onClick={props.onClick}>
      <span role="img" aria-label="close">
        ❌
      </span>
    </button>
  );
};

export default CloseButton;
