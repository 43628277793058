import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, RouteComponentProps } from 'react-router-dom';

import MakeRecord from '../RecordForm/MakeRecord';
import { log, getById, list } from '../../services/db';
import { omit } from 'ramda';
import { ILogTemp } from '../../services/db-class';
import { useLiveQuery } from 'dexie-react-hooks';
import List from '../List/List';
import Flexbox from '../Layout/Flexbox';
import useAutocomplete from '../../hooks/useAutocomplete';

const FormWrapper = styled.div`
  padding: 10px;
`;

const blankState = (): ILogTemp => ({
  event: '',
  amount: 0
});

interface Props
  extends RouteComponentProps<{
    uuid: string;
  }> {
  duplicate?: boolean;
}

const Create = (props: Props) => {
  const { duplicate } = props;
  const lastN = 5;
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<ILogTemp>(blankState());
  const items = useLiveQuery(() => list(1, lastN));
  const autocomplete = useAutocomplete();

  useEffect(() => {
    const uuid = props.match.params.uuid;

    if (!uuid && loading) {
      setLoading(false);
      return;
    }
    if (uuid)
      getById(uuid).then(res => {
        if (!!res) {
          if (duplicate) {
            setState(omit(['uuid', 'date'], res));
          } else {
            setState(res);
          }
        }

        setLoading(false);
      });
  }, [props.match.params.uuid, loading, duplicate]);

  return (
    <FormWrapper>
      {!loading && (
        <MakeRecord
          autocompleteData={autocomplete}
          onSubmit={log}
          redirect={props.history.push}
          initialData={state}
        />
      )}

      <section style={{ marginTop: '2rem' }}>
        <div>Last {lastN} records:</div>
        <List items={items ?? []} />
        <Flexbox justifyContent="center">
          <Link to="/list">more</Link>
        </Flexbox>
      </section>
    </FormWrapper>
  );
};

export default Create;
