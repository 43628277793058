import React from 'react';
import ReactDOM from 'react-dom';
import Router from './Router';
import registerServiceWorker from './registerServiceWorker';
import { initDb } from './services/db';

import '@reach/tooltip/styles.css';

import './index.css';
import './utils.global.css';

// god forbid
initDb('main');

ReactDOM.render(React.createElement(Router), document.getElementById('root'));

registerServiceWorker();
