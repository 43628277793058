import styled from 'styled-components';

export const Alert = styled.div`
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
`;
export const SuccessAlert = styled(Alert)`
  border: 1px solid green;
  background-color: #a9eea9;
`;
