import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Create from './components/Routes/Create';
import Options from './components/Routes/Options';
import List from './components/Routes/List';
import Menu from './components/Menu/Menu';
import ConfigureInputs from './components/ConfigureInputs/ConfigureInputs';
import CustomValuesProvider from './store/CustomValueProvider';

export default function AppRouter() {
  return (
    <Router>
      <div>
        <Menu />
        <div className="App__container">
          <CustomValuesProvider>
            <Switch>
              <Route exact path="/" component={Create} />
              <Route path="/list" component={List} />
              <Route path="/configuration" component={ConfigureInputs} />
              <Route path="/options" component={Options} />
              <Route
                path="/edit/:uuid"
                render={match => <Create {...match} />}
              />
              <Route
                path="/duplicate/:uuid"
                render={match => <Create duplicate {...match} />}
              />
              <Route path="*" component={Create} />
            </Switch>
          </CustomValuesProvider>
        </div>
      </div>
    </Router>
  );
}
