import React, { Component, Fragment } from 'react';
import { importData } from '../../services/db';
import styled from 'styled-components';
import { SuccessAlert } from '../Messages/Alert';

const Wrapper = styled.div`
  padding: 0.5rem;
`;
export default class Import extends Component {
  state = {
    content: '',
    finished: false
  };
  handleImport = ({
    target: { files }
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (files === null) return;
    const validFiles = Array.from(files).filter(
      file =>
        file.name.startsWith('export-') && file.type === 'application/json'
    );
    if (validFiles.length < 1) throw new Error('no valid files');
    const fileReader = new FileReader();

    fileReader.onload = e => {
      if (e.target === null) return;
      this.setState({
        content: e.target.result
      });
    };

    fileReader.readAsText(validFiles[0]);
  };
  importFromTextarea = () => {
    importData(JSON.parse(this.state.content));
    this.setState({
      finished: true,
      content: ''
    });
  };

  render() {
    const { content, finished } = this.state;
    return (
      <Fragment>
        <Wrapper>
          {finished ? (
            <SuccessAlert>Successfully imported data!</SuccessAlert>
          ) : (
            <>
              Upload file you exported earlier
              <input type="file" onChange={this.handleImport} />
              <textarea
                className="textarea"
                onChange={({ target: { value } }) =>
                  this.setState({
                    content: value
                  })
                }
                rows={10}
                value={content}
              />
              {content.length > 0 && (
                <button className="button" onClick={this.importFromTextarea}>
                  Import data into this device
                </button>
              )}
            </>
          )}
        </Wrapper>
      </Fragment>
    );
  }
}
