import React, { useState } from 'react';
import Flexbox from '../Layout/Flexbox';
import { useCustomValuesContext } from '../../store/CustomValueProvider';
// import NumberInput from './NumberInput';
import CloseButton from '../Buttons/CloseButton';
import Select from './Select';
import '@reach/listbox/styles.css';

type Props = {};

const ConfigureInputs = (props: Props) => {
  const { customValuesList, removeCustomValue } = useCustomValuesContext();
  // const [customValue, setCustomValue] = useState<'number' | 'select' | 'multi'>(
  //   'select'
  // );
  const customValue = 'select';
  const [name, setName] = useState('');

  return (
    <>
      <Flexbox flexDirection="column" gap="0.5rem">
        {customValuesList.map(cv => (
          <Flexbox key={cv.uuid} justifyContent="space-between">
            <strong>{cv.event}</strong>

            <div>
              [{!!cv.options?.length ? `${cv.options.length} options` : 'input'}
              ] <CloseButton onClick={() => removeCustomValue(cv.uuid)} />
            </div>
          </Flexbox>
        ))}
      </Flexbox>

      <hr
        style={{
          margin: '1rem',
          height: 1,
          border: 0,
          borderBottom: '1px solid black'
        }}
      />

      <Flexbox flexDirection="column">
        <label htmlFor="eventName">Event name</label>
        <input
          className="input"
          id="eventName"
          type="text"
          name="eventName"
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
          autoFocus
        />
      </Flexbox>

      {/* <Flexbox gap="1rem" style={{ marginTop: '2rem' }}>
        <Flexbox flexDirection="column" style={{ width: '80%' }}>
          <Listbox
            value={customValue}
            onChange={(val: 'number' | 'select' | 'multi') =>
              setCustomValue(val)
            }
          >
            <ListboxOption value="number">Number input</ListboxOption>
            <ListboxOption value="select">Select</ListboxOption>
            <ListboxOption value="multi">Multi option</ListboxOption>
          </Listbox>
        </Flexbox>
      </Flexbox> */}

      {/* {customValue === 'number' && <NumberInput name={name} />} */}
      {(customValue === 'select' || customValue === 'multi') && (
        <Select name={name} />
      )}
    </>
  );
};

export default ConfigureInputs;
