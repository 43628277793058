import React from 'react';
import { compareDesc } from 'date-fns';
import Item from './Item';
import './List.css';
import { ILog } from '../../services/db-class';

export interface IUDmethods {
  edit?: (item: ILog) => void;
  remove?: (id: string) => void;
}

export type ILogRow = {
  items: ILog[];
  activeId?: string;
} & IUDmethods;

const Log = ({ items, remove, activeId, edit }: ILogRow) => (
  <ol data-cy="record-list" className="List">
    {items
      .sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)))
      .map(item => (
        <Item
          key={item.uuid}
          isActive={activeId === item.uuid}
          remove={remove}
          edit={edit}
          {...item}
        />
      ))}
  </ol>
);

export default Log;
