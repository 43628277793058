import Dexie from 'dexie';
import { recordedDate } from './datetime';
import { DexieDB, ICustomValue, ICustomValueTemp, ILog } from './db-class';
import 'dexie-observable';

type CustomValue = {
  event: string | number;
  unit?: string;
  options?: string[];
};

type CustomValueInDb = {
  uuid: string;
  updatedAt: string;
  value: number | string;
} & CustomValue;

// @ts-ignore
let db: InstanceType<typeof DexieDB> = null;

const initDb = (dbName: string) => {
  db = new DexieDB(dbName);

  // @ts-ignore
  window.db = db;

  return db;
};

const listDbs = () => Dexie.getDatabaseNames();

const getCurrent = () => db.name;

const deleteDb = Dexie.delete;

const log = (putObj: ILog, key?: string) => {
  const insertedObj = { ...putObj, updatedAt: recordedDate() };
  return db.logs.put(insertedObj, key);
};

const getById = (id: string) => db.logs.get(id);

const list = (page = 1, limit = 15) =>
  db.logs
    .orderBy('date')
    .reverse()
    .offset((page - 1) * limit)
    .limit(limit)
    .toArray();

const filteredList = (term: string, page = 1, limit = 15) =>
  db.logs
    .where('event')
    .equalsIgnoreCase(term)
    .sortBy('date')
    .then(res => res.reverse().slice((page - 1) * limit, page * limit));

const remove = (uuid: number | string) =>
  db.logs.where('uuid').equals(uuid).delete();

const count = (): Promise<number> => db.logs.count();

const exportData = async () =>
  JSON.stringify(await db.logs.reverse().toArray());

const importData = (data: any[]) => {
  data.forEach(d => {
    d.importedAt = recordedDate();
    d.updatedAt = d.importedAt;
  });

  return db.logs.bulkAdd(data);
};

const customValues = {
  list: () =>
    db &&
    db.customValues.orderBy('event').reverse().offset(0).limit(999).toArray(),

  upsert: (putObj: ICustomValueTemp, key?: string) => {
    const insertedObj = { ...putObj, updatedAt: recordedDate() };
    // TODO: resolve the type mismatch of missing id
    return db.customValues.put(insertedObj as any as ICustomValue, key);
  },

  remove: (uuid: number | string) =>
    db.customValues.where('uuid').equals(uuid).delete()
};

export {
  customValues,
  getCurrent,
  listDbs,
  deleteDb,
  initDb,
  filteredList,
  db,
  log,
  list,
  getById,
  remove,
  count,
  exportData,
  importData
};

export type { CustomValueInDb, CustomValue };
