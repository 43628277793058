import React, { Component, Fragment } from 'react';
import Import from './Import';
import Export from './Export';
import styled from 'styled-components';
import Flexbox from '../Layout/Flexbox';
import { cx } from '../../helpers/classnames';

const Wrapper = styled.div`
  margin: 1rem 0;
  width: 100%;
  text-align: center;
`;

export default class ImportExport extends Component {
  state = {
    tab: 'Export'
  };

  show(tab: 'Export' | 'Import') {
    return () => {
      this.setState({ tab });
    };
  }

  render() {
    return (
      <Fragment>
        <Wrapper>
          <Flexbox>
            <button
              className={cx(
                'button',
                this.state.tab === 'Export' && 'button-primary'
              )}
              onClick={this.show('Export')}
            >
              Export
            </button>
            <button
              className={cx(
                'button',
                this.state.tab === 'Import' && 'button-primary'
              )}
              onClick={this.show('Import')}
            >
              Import
            </button>
          </Flexbox>
        </Wrapper>
        {this.state.tab === 'Export' ? <Export /> : <Import />}
      </Fragment>
    );
  }
}
