import React, { useEffect, useState } from 'react';
import { formatISO } from 'date-fns';

import { recordedDate } from '../../services/datetime';
import TimeDate from './TimeDate';
import { getById, remove } from '../../services/db';
import { useLocation } from 'react-router-dom';
import { useCustomValuesContext } from '../../store/CustomValueProvider';
import Flexbox from '../Layout/Flexbox';
import { ILog, ILogTemp } from '../../services/db-class';
import { cx } from '../../helpers/classnames';

interface Props {
  initialData?: ILogTemp;
  autocompleteData: string[];
  redirect: (path: string) => void;
  onSubmit: (data: any) => any;
}

type State = ILogTemp & { selection: string[] };

// const isBlank = (state: ILog) => equals(state, blankState());
const isEdit = (state: ILogTemp) => Boolean(state.uuid);
const initialData: ILogTemp = Object.freeze({
  event: '',
  amount: 0
});

const MakeRecord = (props: Props) => {
  const [state, _setState] = useState<State>({
    ...initialData,
    ...props.initialData,
    selection: []
  });
  const { customValuesList } = useCustomValuesContext();
  const { pathname, search } = useLocation();

  let editedUUID: string | null = null;

  const setState = (state: Partial<State>) =>
    _setState(oldState => ({ ...oldState, ...state }));

  const resetForm = () => {
    _setState({ ...initialData, selection: [] });
  };
  if (pathname === '/edit') {
    editedUUID = search.replace('?uuid=', '');
  }

  useEffect(() => {
    if (editedUUID) {
      getById(editedUUID).then(editedRecord => {
        setState({ ...editedRecord });
      });
    } else {
      setState({ ...initialData, ...props.initialData });
    }
  }, [editedUUID, props.initialData]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    const { event, amount, uuid, date, customValue } = state;

    const record: ILogTemp = {
      customValue,
      event,
      amount,
      date: date ? date : recordedDate()
    };

    if (typeof uuid === 'string') record.uuid = uuid;

    props.onSubmit(record);

    if (isEdit(state)) {
      props.redirect('/list');
    } else {
      resetForm();
      props.redirect('/');
    }
  };

  const setInput = (inputName: keyof ILog) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({ [inputName]: e.target.value } as any);
    };
  };

  // const changeDate = (date: string) => {
  //   edit({
  //     ...state,
  //     date
  //   });
  // };

  const toggleNow = (now: boolean) => {
    setState({
      date: now ? undefined : recordedDate()
    });
  };

  const { date, uuid, amount, event } = state;
  const now = date === null || date === undefined;

  const customValue = customValuesList.find(cv => cv.event === event);

  return (
    <form className="recordForm" onSubmit={onSubmit}>
      <TimeDate
        now={now}
        date={state.date ?? formatISO(new Date())}
        changeDate={date => {
          setState({ date });
        }}
        checkNow={toggleNow}
      />

      <div>
        <label className="label" htmlFor="input-event">
          event / symptom
        </label>
        <input
          id="input-event"
          className="input"
          type="text"
          name="event"
          list="event-data"
          onChange={setInput('event')}
          value={event}
        />
        <datalist id="event-data">
          {props.autocompleteData.map(d => (
            <option key={d} value={d} />
          ))}
        </datalist>
      </div>
      <label className="label" htmlFor="input-amount">
        amount
      </label>
      <div>
        <input
          id="input-amount"
          className="input"
          type="number"
          name="amount"
          onChange={setInput('amount')}
          value={amount}
        />
      </div>

      {customValue?.options && (
        <div style={{ marginTop: '1rem' }}>
          <div>custom value for {event} </div>
          <Flexbox gap="0.5rem">
            {customValue.options.map(o => (
              <button
                type="button"
                className={cx(
                  'button',
                  state.customValue === o && 'button-primary'
                )}
                style={{
                  width: '100%'
                }}
                onClick={() => setState({ customValue: o })}
                key={o}
              >
                {o}
              </button>
            ))}
          </Flexbox>
        </div>
      )}

      <div className="spaceEvenly" style={{ margin: '1rem 0' }}>
        <button className="button button-primary" type="submit">
          {isEdit(state) ? 'Edit' : 'Record'}
        </button>
        {isEdit(state) && (
          <button
            className="button button-danger"
            type="button"
            onClick={() => {
              uuid && remove(uuid);
              props.redirect('/list');
            }}
          >
            Delete
          </button>
        )}
      </div>
      {/* {!isBlank(state) && (
          // @ts-ignore
          <Button icon="close-circle-o" type="secondary" onClick={reset}>
            reset
          </Button>
        )} */}
    </form>
  );
};

export default MakeRecord;
