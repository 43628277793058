import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState
} from 'react';
import { customValues } from '../services/db';
import { ICustomValue, ICustomValueTemp } from '../services/db-class';

type Val = {
  customValuesList: ICustomValue[];
  upsertCustomValue: (
    putObj: ICustomValueTemp,
    key?: string | undefined
  ) => void;
  removeCustomValue: (uuid: string) => void;
};

const CustomValueContext = createContext<Val | undefined>(undefined);

export const CustomValuesProvider: FC = props => {
  const [customValuesList, setCustomValuesList] = useState<ICustomValue[]>([]);

  const update = () => {
    customValues.list().then(setCustomValuesList);
  };

  useEffect(() => {
    update();
  }, [setCustomValuesList]);

  const upsertCustomValue = (
    putObj: ICustomValueTemp,
    key?: string | undefined
  ) => {
    customValues.upsert(putObj, key);

    update();
  };

  const removeCustomValue = (uuid: string) => {
    customValues.remove(uuid);

    update();
  };

  return (
    <CustomValueContext.Provider
      value={{ customValuesList, upsertCustomValue, removeCustomValue }}
    >
      {props.children}
    </CustomValueContext.Provider>
  );
};

export const useCustomValuesContext = () => {
  const context = useContext(CustomValueContext);
  if (context === undefined) {
    throw new Error(
      'useEventPageContext must be used within EventPageContext.Provider'
    );
  }
  return context;
};

export default CustomValuesProvider;
