import React, { Component } from 'react';
import PaginatedList from '../List/PaginatedList';

class List extends Component {
  render() {
    return <PaginatedList />;
  }
}

export default List;
