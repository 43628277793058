import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate, showDate } from '../../services/datetime';
import { IUDmethods } from './List';
import { ILog } from '../../services/db-class';
import { FaRegPlusSquare } from 'react-icons/fa';
import Tooltip from '@reach/tooltip';
import './List.css';

export type ItemComponent = {
  isActive: boolean;
} & ILog &
  IUDmethods;

const Item = ({ uuid, event, date, amount, customValue }: ItemComponent) => (
  <li
    className="ListItem"
    style={{
      display: 'flex'
    }}
  >
    <Link
      to={`/edit?uuid=${uuid}`}
      key={uuid + date}
      className="ListRow flex-trunc-parent"
    >
      <Tooltip label={formatDate(date)}>
        <div>{showDate(date)}</div>
      </Tooltip>
      <span className="ListItem__field ListItem__event flex-trunc-child tal">
        {event}
      </span>
      <strong className="ListItem__field tar">{customValue}</strong>
      {Number(amount) !== 0 && (
        <span className="ListItem__field tar">{amount}</span>
      )}
    </Link>
    <Link to={`/duplicate/${uuid}`}>
      <button
        aria-label="clone"
        className="button-clear"
        style={{ padding: '2px 1rem' }}
      >
        <FaRegPlusSquare />
      </button>
    </Link>
  </li>
);

export default Item;
