import React from 'react';
import Flexbox from '../Layout/Flexbox';

type Props = {
  next: () => void;
  prev: () => void;
  current: number;
  total: number;
};

const Pagination = (props: Props) => {
  return (
    <Flexbox alignItems="center" gap="2rem">
      <button
        className="button"
        onClick={props.prev}
        disabled={props.current <= 1}
      >
        prev
      </button>
      <div style={{ fontSize: '1.5rem' }}>{props.current}</div>

      <button
        className="button"
        onClick={props.next}
        disabled={props.current >= props.total}
      >
        next
      </button>
    </Flexbox>
  );
};

export default Pagination;
