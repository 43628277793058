import React, { useState } from 'react';
import Flexbox from '../Layout/Flexbox';
import { useCustomValuesContext } from '../../store/CustomValueProvider';

type CustomFormProps = {
  name: string;
  uuid?: string;
};

const Select = (props: CustomFormProps) => {
  const { customValuesList, upsertCustomValue } = useCustomValuesContext();
  const [options, setOptions] = useState<string[]>(['']);
  const [isScale, setIsScale] = useState(false);
  const [scale, setScale] = useState(5);
  const [scaleStep, setScaleStep] = useState(1);

  const calculatedOptions = isScale
    ? Array(scale)
        .fill(0)
        .map((_n, i) => String((i + 1) * scaleStep))
    : options.filter(Boolean);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    upsertCustomValue({
      event: props.name.trim(),
      options: calculatedOptions
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="form"
      style={{ padding: '1rem 0' }}
    >
      <Flexbox flexDirection="column" gap="0.5rem">
        <Flexbox gap="1rem">
          <label>
            <input
              className="mr1"
              type="radio"
              checked={!isScale}
              onChange={() => setIsScale(false)}
            />
            Options
          </label>
          <label>
            <input
              className="mr1"
              type="radio"
              checked={isScale}
              onChange={() => setIsScale(true)}
            />
            Scale
          </label>
        </Flexbox>

        {isScale ? (
          <Flexbox gap="0.5rem" alignItems="center">
            <div>from 1 to</div>
            <input
              type="number"
              name="scale_end"
              min={2}
              max={10}
              value={scale}
              onChange={e => setScale(Number(e.target.value))}
            />
            step
            <input
              type="number"
              name="scale_step"
              value={scaleStep}
              onChange={e => setScaleStep(Number(e.target.value))}
            />
          </Flexbox>
        ) : (
          <Flexbox flexDirection="column" gap="0.5rem">
            {options.map((option, i) => (
              <div key={i}>
                <input
                  type="text"
                  value={option}
                  onChange={e => {
                    const newOptions = [...options];
                    const newValue = e.target.value;
                    const isLast = options.length - 1 === i;

                    newOptions[i] = newValue;

                    if (isLast && newValue) {
                      newOptions.push('');
                    }

                    setOptions(newOptions);
                  }}
                />
                <small className="ml1">Option {i + 1}</small>
              </div>
            ))}
          </Flexbox>
        )}

        {isScale ? (
          <div>Will add {calculatedOptions.join(', ')}</div>
        ) : (
          <div>
            Will add {options.filter(Boolean).length} options. (empty inputs are
            ignored)
          </div>
        )}

        <button
          type="submit"
          disabled={
            !props.name && customValuesList.some(cv => cv.event === props.name)
          }
        >
          Create
        </button>
      </Flexbox>
    </form>
  );
};

export default Select;
